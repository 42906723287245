import React, { useState, useEffect } from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
// import { gsap } from "gsap"
// import $ from "jquery"

import "../css/gallery.css"

const Gallery = props => {
  const [isDown, setIsDown] = useState(false)
  const [startX, setStartX] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)
  const [currentImage, setCurrentImage] = useState(0)

  const adjustSamplesHeight = () => {
    const html = document.documentElement
    const headerHeight = document.querySelector("#project-header").offsetHeight
    let newBottom = Math.ceil(
      html.clientWidth / 1.92 -
        html.clientHeight +
        56 +
        headerHeight -
        window.scrollY
    )
    if (newBottom >= 20) {
      document.querySelector(".samples").style.bottom = newBottom + "px"
    }
  }

  useEffect(() => {
    const html = document.documentElement
    if (html.clientWidth / html.clientHeight > 1.92) {
      const headerHeight = document.querySelector("#project-header")
        .offsetHeight
      const newBottom = Math.ceil(
        html.clientWidth / 1.92 - html.clientHeight + 56 + headerHeight - window.scrollY
      )
      document.querySelector(".samples").style.bottom = newBottom + "px"
      window.addEventListener("scroll", adjustSamplesHeight)
      window.addEventListener("resize", adjustSamplesHeight)
    }

  }, [])

  const changeSrcset = (srcset, src) => {
    document
      .getElementById("logo-wrapper")
      .getElementsByClassName(
        "gatsby-image-wrapper"
      )[0].children[2].children[0].srcset = srcset
    document
      .getElementById("logo-wrapper")
      .getElementsByClassName("gatsby-image-wrapper")[0].children[2].children[1].removeAttribute('srcset')
    document
      .getElementById("logo-wrapper")
      .getElementsByClassName(
        "gatsby-image-wrapper"
      )[0].children[2].children[1].src = src
  }

  const changeMainBG = async (evt, i, arrows = false) => {
    setCurrentImage(i)

    if (arrows) {
      changeSrcset(evt.srcset, evt.src)
    } else {
      changeSrcset(evt.target.srcset, evt.target.src)
    }

    const slider = document.querySelector("#samples-container")

    slider.scroll({
      left:
        ((i - 1) *
          (props["imagesArray"].length * 193 -
            20 -
            document.querySelector("#samples-wrapper").getBoundingClientRect()
              .width)) /
        (props["imagesArray"].length - 3),
      behavior: "smooth",
    })
  }

  const changeMainBGPrevious = () => {
    if (currentImage !== 0) {
      const element = {
        src: document.querySelector(`.gallery-${currentImage - 1}`).children[2].children[1].src,
        srcset : document.querySelector(`.gallery-${currentImage - 1}`).children[2].children[0].srcset
      }
      changeMainBG(
        element,
        currentImage - 1,
        true
      )
    }
  }

  const changeMainBGNext = () => {
    if (currentImage !== props["imagesArray"].length - 1) {
      const element = {
        src: document.querySelector(`.gallery-${currentImage + 1}`).children[2].children[1].src,
        srcset : document.querySelector(`.gallery-${currentImage + 1}`).children[2].children[0].srcset
      }
      changeMainBG(
        element,
        currentImage + 1,
        true
      )
    }
  }

  const imagesElements = () => {
    return props["imagesArray"].map((image, ind) => {
      let sampleClass
      if (ind !== 0 && ind !== props["imagesArray"].length - 1) {
        sampleClass = "img-line sample"
      } else {
        if (ind === 0) {
          sampleClass = "img-line sample sample-first"
        } else {
          sampleClass = "img-line sample sample-last"
        }
      }
      var imgObj = {}
      imgObj[["childImageSharp"]] = image[Object.keys(image)[0]][0]
      return (
        <div className={sampleClass} key={ind}>
          <Button
            onClick={e => changeMainBG(e, ind)}
            className="gallery-image-button"
          >
            <GatsbyImage
              image={getImage(imgObj)}
              backgroundColor={false}
              className={`img-responsive gallery-${ind}`}
              alt={props.company}
              draggable={false}
            />
          </Button>
        </div>
      )
    })
  }

  const handleMouseDown = e => {
    const slider = document.querySelector("#samples-container")
    setIsDown(true)
    setStartX(e.pageX - slider.offsetLeft)
    setScrollLeft(slider.scrollLeft)
  }

  const handleMouseLeave = () => {
    setIsDown(false)
  }

  const handleMouseUp = () => {
    setIsDown(false)
  }

  const handleMouseMove = e => {
    if (!isDown) return
    e.preventDefault()
    const slider = document.querySelector("#samples-container")
    const x = e.pageX - slider.offsetLeft
    const walk = x - startX //* 3 //scroll-fast

    slider.scrollLeft = scrollLeft - walk
  }

  let imgObj = {}
  imgObj[["childImageSharp"]] = props["imagesArray"][0]["childrenImageSharp"][0]
  return (
    <>
      <Container className="big-container">
        <Row>
          <Col>
            <div id="logo-wrapper" style={{ margin: 0 }}>
              <GatsbyImage
                image={getImage(imgObj)}
                backgroundColor={false}
                alt={props.company}
                style={{
                  left: 0,
                  top: 0,
                }}
              />
              <Container className="samples">
                <Row>
                  <Col sm={1} style={{ paddingRight: 0 }}>
                    <Button
                      onClick={e => changeMainBGPrevious(e)}
                      className="gallery-image-button"
                      style={{ marginTop: "10px", float: "right" }}
                    >
                      <img
                        src="../../gallery/left.png"
                        alt="left"
                        className="img-responsive gallery-arrow"
                        draggable={false}
                      />
                    </Button>
                  </Col>
                  <Col
                    id="samples-wrapper"
                    sm={10}
                    onMouseDown={e => handleMouseDown(e)}
                    onMouseLeave={handleMouseLeave}
                    onMouseUp={handleMouseUp}
                    onMouseMove={e => handleMouseMove(e)}
                  >
                    <div id="samples-container" style={{ overflow: "hidden" }}>
                      {imagesElements()}
                    </div>
                  </Col>
                  <Col sm={1} style={{ marginTop: "10px", float: "left" }}>
                    <Button
                      onClick={e => changeMainBGNext(e)}
                      className="gallery-image-button"
                      style={{ maginTop: "10px" }}
                    >
                      <img
                        src="../../gallery/right.png"
                        alt="right"
                        className="img-responsive gallery-arrow"
                        draggable={false}
                      />
                    </Button>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Gallery
